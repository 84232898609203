import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { CardGrid } from "@organisms";
import { PageHero, ContactDetails } from "@molecules";
import { Container } from "@atoms";

const ContactPage = ({ data }) => {
  const { hero, team, meta } = data;
  return (
    <PageContainer contact meta={meta}>
      <PageHero {...hero} fill />
      <CardGrid icons={false} horizontal {...team} />
      <Container variant="xl" className="pb-8">
        <ContactDetails />
      </Container>
    </PageContainer>
  );
};

export default ContactPage;
