import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { ContentForm } from "@organisms";

const LandingPage = ({ data }) => {
  const { body, meta } = data;
  return (
    <PageContainer meta={meta}>
      <ContentForm {...body} />
    </PageContainer>
  );
};

LandingPage.defaultProps = {};

export default LandingPage;
