import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { PageHero, ImageCopyBlock, BlocksSection } from "@molecules";
import { Container } from "@atoms";

const LivingHerePage = ({ data }) => {
  const { hero, intro, blocks, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl">
        <div className="pt-4 md:pt-0 pb-16 md:pb-24">
          <ImageCopyBlock {...intro} large />
        </div>
      </Container>
      <BlocksSection {...blocks} className="bg-gray-light" />
    </PageContainer>
  );
};

export default LivingHerePage;
