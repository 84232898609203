import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Container } from "@atoms";
import {
  PageHero,
  FeatureCarousel,
  ImageCopyBlock,
  QuickStats,
  CountyBusinesses,
  CountyCities,
  BlocksSection,
} from "@molecules";

const SnapshotPage = ({ data }) => {
  const { hero, carousel, intro, stats, industry, cities, incentives, meta } =
    data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} />
      <FeatureCarousel carousel={carousel} />
      <Container variant="xl" className="py-16 md:py-24">
        <ImageCopyBlock {...intro} />
      </Container>
      <QuickStats {...stats} />
      <CountyBusinesses {...industry} />
      <CountyCities {...cities} />
      <BlocksSection {...incentives} />
    </PageContainer>
  );
};

SnapshotPage.defaultProps = {};

export default SnapshotPage;
