import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { HomeImageGrid, BlogPreview } from "@organisms";
import { HeroGrid, HomeOverview } from "@molecules";

const HomePage = ({ data }) => {
  const { hero, intro, ctas, blog, meta } = data;
  return (
    <PageContainer meta={meta}>
      <HeroGrid {...hero} />
      <HomeOverview {...intro} />
      <HomeImageGrid {...ctas} />
      <BlogPreview {...blog} />
    </PageContainer>
  );
};

HomePage.defaultProps = {};

export default HomePage;
