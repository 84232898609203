import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { EventCard } from "@molecules";
import { Container, ButtonAlt } from "@atoms";

const EventPage = ({ data }) => {
  const { event, meta } = data;
  return (
    <PageContainer meta={meta}>
      <Container variant="xl" className="py-16 md:py-24">
        <div className="flex justify-end">
          <ButtonAlt
            to="/events"
            color="blue"
            showIcon
            reverse
            className="text-blue"
          >
            Back to Events
          </ButtonAlt>
        </div>
        <EventCard {...event} />
      </Container>
    </PageContainer>
  );
};

EventPage.defaultProps = {};

export default EventPage;
