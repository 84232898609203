import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { ContentBuilder } from "@organisms";
import { PageHero } from "@molecules";
import { Container, CbContainer } from "@atoms";

const ContentBuilderPage = ({ data }) => {
  const { hero, contentBuilder, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl" className="mt-24">
        <CbContainer>
          <ContentBuilder blocks={contentBuilder} />
        </CbContainer>
      </Container>
    </PageContainer>
  );
};

ContentBuilderPage.defaultProps = {};

export default ContentBuilderPage;
