import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { PageHero, FeatureCarousel, BlocksSection } from "@molecules";
import { CardGrid } from "@organisms";

const BusinessPage = ({ data }) => {
  const { hero, carousel, intro, cardGrid, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} />
      <FeatureCarousel carousel={carousel} />
      <BlocksSection {...intro} />
      <CardGrid {...cardGrid} className="bg-gray-light" />
    </PageContainer>
  );
};

export default BusinessPage;
