import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { BlogPreview } from "@organisms";
import { PageHero, FeaturedArticle, CategoriesDropdown } from "@molecules";
import { Container, Text } from "@atoms";

const BlogIndexPage = ({ data }) => {
  const { hero, featured, blogPosts, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl" className="py-16 md:py-24">
        <div className="flex justify-between">
          <Text variant="lg" className="text-blue font-bold">
            {featured.heading}
          </Text>
          <CategoriesDropdown />
          {/* <DropDown
            placeholder="categories"
            model={[category, setCategory]}
            options={categories}
          /> */}
        </div>
        <FeaturedArticle {...featured.article} />
      </Container>
      <BlogPreview {...blogPosts} color="gray" />
    </PageContainer>
  );
};

BlogIndexPage.defaultProps = {};

export default BlogIndexPage;
