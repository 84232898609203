import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { CardGrid, ContentBuilder } from "@organisms";
import { PageHero } from "@molecules";
import { Container, CbContainer } from "@atoms";

const InitiativePage = ({ data }) => {
  const { hero, contentBuilder, inits, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl" className="mt-24">
        <CbContainer>
          <ContentBuilder blocks={contentBuilder} />
        </CbContainer>
      </Container>
      <CardGrid {...inits} />
    </PageContainer>
  );
};

InitiativePage.defaultProps = {};

export default InitiativePage;
