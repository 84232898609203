import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { Container } from "@atoms";
import { PageHero, ImageCopyBlock, BlocksSection } from "@molecules";

const TransportationPage = ({ data }) => {
  const { hero, intro, blocks, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl" className="pt-10 pb-16 md:pt-0 md:pb-24">
        <ImageCopyBlock {...intro} large />
      </Container>
      <BlocksSection {...blocks} className="bg-gray-light" />
    </PageContainer>
  );
};

TransportationPage.defaultProps = {};

export default TransportationPage;
