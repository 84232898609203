import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { CardGrid } from "@organisms";
import { PageHero } from "@molecules";

const InitiativesIndexPage = ({ data }) => {
  const { hero, inits, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} />
      <CardGrid {...inits} />
    </PageContainer>
  );
};

InitiativesIndexPage.defaultProps = {};

export default InitiativesIndexPage;
