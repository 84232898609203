import React from "react";
import { formatDate } from "@utils";
import { DefaultPageContainer as PageContainer } from "@layout";
import { ContentBuilder } from "@organisms";
import { EmailForm, Share, RecentArticles } from "@molecules";
import { Container, Text, Image, Icon } from "@atoms";

const ArticlePage = ({ data }) => {
  const { title, url, date, author, contentBuilder, uid, meta } = data;
  return (
    <PageContainer meta={meta}>
      <Container variant="xl" className="py-16 md:py-24">
        <div className="flex flex-wrap -mx-8">
          <div className="w-full md:w-2/3 space-y-8 px-8">
            <Text variant="xxl" className="font-bold text-blue">
              {title}
            </Text>
            <div className="flex items-center">
              <div className="flex items-center justify-center w-16 h-16 rounded-full bg-blue relative">
                <Icon name="arrowhead" className="w-6 h-6 text-gold" />
                <Image {...author.image} />
              </div>
              <div className="flex flex-col text-xxs text-gray space-y-2 ml-4">
                <span>{formatDate(date, "MMMM dd, yyyy")}</span>
                {author && <span>{`Author: ${author.name}`}</span>}
              </div>
            </div>
            <ContentBuilder blocks={contentBuilder} />
            <Share title={title} url={url} />
          </div>
          {/* sidebar */}
          <div className="w-full md:w-1/3 space-y-8 px-8">
            <div>
              <Text variant="lg" className="text-gold font-bold">
                Related Articles
              </Text>
              <RecentArticles uid={uid} />
            </div>
            <div className="space-y-4">
              <Text variant="lg" className="text-gold font-bold">
                Get Our Newsletter
              </Text>
              <EmailForm condensed />
            </div>
          </div>
        </div>
      </Container>
    </PageContainer>
  );
};

export default ArticlePage;
