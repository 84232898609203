import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { BlogPreview } from "@organisms";
import { PageHero } from "@molecules";

const BlogCategoryPage = ({ data }) => {
  const { hero, blogPosts, meta } = data;
  // const [category, setCategory] = useState(0);
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      {/* <Container variant="xl" className="py-16 md:py-24">
        <div className="flex justify-between">
          <Text variant="lg" className="text-blue font-bold">
            {featured.heading}
          </Text>
           <DropDown
            placeholder="categories"
            model={[category, setCategory]}
            options={categories}
          /> 
        </div>
        <FeaturedArticle {...featured.article} />
      </Container> */}
      <BlogPreview {...blogPosts} color="gray" />
    </PageContainer>
  );
};

BlogCategoryPage.defaultProps = {};

export default BlogCategoryPage;
