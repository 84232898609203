import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { CardGrid } from "@organisms";
import { PageHero } from "@molecules";
import { Container, CbContainer } from "@atoms";
import ContentBuilder from "../components/organisms/ContentBuilder";

const ResourcePage = ({ data }) => {
  const { hero, contentBuilder, resources, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <Container variant="xl" className="mt-24">
        <CbContainer>
          <ContentBuilder blocks={contentBuilder} />
        </CbContainer>
      </Container>
      <CardGrid icon {...resources} />
    </PageContainer>
  );
};

ResourcePage.defaultProps = {};

export default ResourcePage;
