import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { DataOverview, StatsSection } from "@organisms";
import { PageHero } from "@molecules";

const DataPage = ({ data }) => {
  const { hero, overview, statBlocks, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <DataOverview {...overview} />
      <StatsSection blocks={statBlocks} />
    </PageContainer>
  );
};

DataPage.defaultProps = {};

export default DataPage;
