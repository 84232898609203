import React from "react";
import loadable from "@loadable/component";
import { DefaultPageContainer as PageContainer } from "@layout";

const Map = loadable(() => import("@organism/Map"));

const MapPage = ({ data }) => {
  const { meta } = data;
  return (
    <PageContainer meta={meta}>
      <div className="min-h-screen w-screen mt-20 bg-gray-light flex items-center justify-center relative">
        <Map
          fallback={
            <div className="uppercase text-xs flex items-center justify-center absolute inset-0">
              loading...
            </div>
          }
        />
      </div>
    </PageContainer>
  );
};

MapPage.defaultProps = {};

export default MapPage;
