import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { PageHero, Heading } from "@molecules";
import { AreaMaps } from "@organism";

import { Container } from "@atoms";

const AreaMapsPage = ({ data }) => {
  const { hero, intro, maps, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <section className="py-16 md:py-24">
        <Container variant="xl">
          <Heading {...intro} />
          <AreaMaps maps={maps} />
        </Container>
      </section>
    </PageContainer>
  );
};

AreaMapsPage.defaultProps = {};

export default AreaMapsPage;
