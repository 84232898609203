import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { PageHero, BlocksSection } from "@molecules";
import { OurTeam } from "@organisms";

const AboutPage = ({ data }) => {
  const { hero, intro, services, team, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} />
      <BlocksSection {...intro} large />
      <BlocksSection {...services} className="bg-gray-light" />
      <OurTeam team={team} />
    </PageContainer>
  );
};

export default AboutPage;
