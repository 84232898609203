import React from "react";
import { DefaultPageContainer as PageContainer } from "@layout";
import { PageHero } from "@molecules";
import { CardGrid } from "@organisms";

const ToolsResourcesPage = ({ data }) => {
  const { hero, resources, meta } = data;
  return (
    <PageContainer meta={meta}>
      <PageHero {...hero} fill />
      <CardGrid {...resources} showContact />
    </PageContainer>
  );
};

export default ToolsResourcesPage;
